.address-title{
    font-size: 1.15rem;
    font-weight: 600;
    margin-top: 1.25rem;
    background-color: #b7b7b5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px solid #808080;
    border-left: 1px solid #808080;
    border-right: 1px solid #808080;
}

.address-body{
    background-color: #f3f3f0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 1rem;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #808080;
}

.address-body p{
    margin-bottom: 7px;
    font-size: 0.85rem;
}
.address-body p:first-child{
    font-weight: bold;
}