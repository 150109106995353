.dashboard-item {
    background-color: #007bff; /* Arka plan rengi */
    color: #fff; /* Yazı rengi */
    padding: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 90px;
}

.item-content {
    display: flex;
    align-items: center;
}

.item-row {
    display: flex;
    align-items: center;
}

.item-title {
    font-weight: bold;
    margin-right: 10px; /* Title ve daire arasına yatay boşluk ekler */
    text-align: center;
}

.value-circle {
    color: #1e1d1d;
    background-color: #fff; /* Daire rengi */
    border-radius: 50%;
    width: 50px; /* Daire genişliği */
    height: 50px; /* Daire yüksekliği */
    display: flex;
    justify-content: center;
    align-items: center;
}
