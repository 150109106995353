.sidebar-container {
    width: max-content;
}

.sidebar {
    background-color: #ededed;
    color: #2c2a2a;
    padding: 24px 16px 24px 16px;
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    border-radius: 5px;
}

.sidebar-header {
    margin-bottom: 24px;
    width: 100%;
}

.sidebar-header .title {
    height: 32px;
    font-size: 32px;
    margin-bottom: 24px;
}

.sidebar-item {
    cursor: pointer;
    margin-bottom: 12px;
    width: 100%;
}

.list-item {
    padding: 8px 15px 8px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
    height: 40px;
    width: 100%;
}

.list-item:hover {
    background-color: #B2B2B2;
}

.sidebar-item .selected {
    background-color: #2a2c31;
    color: white;
}

.sidebar-item a {
    color: inherit;
    text-decoration: none;
    display: block;
    transition: background-color 0.2s ease-in-out;
    margin-top: 5px;
}

.submenu-item:hover {
    background-color: #a3b18a;
    color: #32332b;
}

.submenu {
    margin-left: 30px;
}

.submenu-item {
    padding: 5px 5px 5px 10px;
    border-radius: 5px;
}


.spec-hover {
    position: absolute;
    left: 72px;
    width: max-content;
    height: 40px;
    text-align: center;
}

.spec-hover > span::before{
    content: "";
    position: absolute;
    top: 48%;
    left: -10px;
    width: 12px;
    height: 12px;
    background: #2a2c31;
    transform: rotate(45deg) translateY(-50%);

}

.spec-hover > span {
    padding: 8px;
    background-color: #2a2c31;
    color: white;
    border-radius: 5px;
}
