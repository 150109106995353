.pass{
    margin: -10px 0 25px 5px;
    color: #a6a6a6;
    cursor: pointer;
}

.pass:hover{
    text-decoration: underline;
}

.signup_link{
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    color: #666666;
}

.signup_link a{
    color: #2691d9;
    text-decoration: none;
}

.signup_link a:hover{
    text-decoration: underline;
}