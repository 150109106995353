.product-cart-box:hover{
    transform: scale(1.05);
    transition: 0.45s;
}

.product-cart-disabled img{
    filter: grayscale(90%);
}

.overlay {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 45px;
    background-color: #bdb2b2;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2d2d2d;
    font-size: 28px;
}



.select-box {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border-radius: 4px; /* Köşeleri biraz sertleştirin */
    border: 2px solid #444; /* Siyahımsı border rengi ve genişliği */
    padding: 8px 16px; /* Yukarıdan aşağıya ve soldan sağa padding ekleyin */
    color: #444; /* Yazı rengi */
    cursor: pointer; /* Cursor'ı el şeklinde yapın */
}

.select-box:focus {
    outline: none; /* Seçenek kutusu odaklandığında dış çizgiyi kaldırın */
}

