.small-img-group{
    display: flex;
    justify-content: space-between;
}

.small-img-col{
    flex-basis: 24%;
    cursor: pointer;
}

.sproduct{
    margin-left: 1500px;
}

.sproduct select{
    display: block;
    padding: 5px 10px;
}

.sproduct input{
    width: 50px;
    height: 40px;
    padding-left: 10px;
    font-size: 20px;
    margin-right: 10px;
}

.sproduct input:focus{
    outline: none;
}

.buy-btn{
    background: #61dafb;
    opacity: 1;
    transition: 0.5s all;
}